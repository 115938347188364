import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Grid, Typography, FormControlLabel, FormGroup, Checkbox, Button } from '@mui/material';
import useFetch from 'src/react-hydra-query/useFetch';
import { OPTIN_LIST } from 'src/endpoints/communicationApi';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveStep, getContactId } from 'src/selectors/saleContract';
import useDerivedState from 'src/react-form/useDerivedState';
import useRequestCallback from 'src/react-hydra-query/useRequestCallback';
import { setStep } from 'src/reducers/saleContract';

const emptyArray = [];
const CommunicationStep = () => {
  const contact = useSelector(getContactId);
  const activeStep = useSelector(getActiveStep);
  const dispatch = useDispatch();

  const { data } = useFetch({
    url: OPTIN_LIST,
    params: {
      contactId: contact,
      type: ['advertising', 'partners', 'news'],
    },
  });
  const optinsList = data?.['hydra:member'] ?? emptyArray;

  const groupedOptins = useMemo(() => {
    const result = optinsList.reduce((acc, optin) => {
      if (!acc[optin.type]) acc[optin.type] = [];
      acc[optin.type].push(optin);
      return acc;
    }, {});
    return result;
  }, [optinsList]);

  const [optins, setOptins] = useDerivedState(() => {
    const status = {};

    if (!optinsList) return status;
    optinsList.forEach((optin) => {
      if (status[optin.type] === undefined) status[optin.type] = optin.isAuthorized;
      if (status[optin.type] !== optin.isAuthorized) status[optin.type] = 'mixed';
    });
    return status;
  }, [optinsList]);

  const onChangeOptin = (type, value) =>
    setOptins({
      ...optins,
      [type]: value,
    });

  const changeOptin = useRequestCallback(
    async (request, optinType, optinValue) => {
      await Promise.all(
        groupedOptins[optinType].map((optin) =>
          request.put(optin['@id'], {
            isAuthorized: optinValue,
          })
        )
      );
    },
    [groupedOptins]
  );

  const onSubmit = useCallback(async () => {
    await Object.keys(optins).map(async (type) => changeOptin(type, optins[type]));
    dispatch(setStep(activeStep + 1));
  }, [activeStep, changeOptin, dispatch, optins]);

  return (
    <Grid container spacing={1} direction="column" style={{ height: '100%', textAlign: 'center' }}>
      <Grid item>
        <Typography style={{ marginTop: 0 }} variant="h5">
          <FormattedMessage id="sale-contract.communication.title" />
        </Typography>
        <Typography variant="subtitle1">
          <FormattedMessage id="sale-contract.communication.subtitle" />
        </Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          flex: 1,
        }}
      >
        <Grid item>
          {Object.keys(optins).map((optinType) => (
            <FormGroup key={optinType} row>
              <FormControlLabel
                checked={optins[optinType]}
                control={<Checkbox color="primary" indeterminate={optins[optinType] === 'mixed'} />}
                onChange={(e) => onChangeOptin(optinType, e.target.checked)}
                label={<FormattedMessage id={`sale-contract.communication.optin.${optinType}`} />}
                style={{ marginBottom: '20px', textAlign: 'left' }}
              />
            </FormGroup>
          ))}
        </Grid>
      </Grid>
      <Grid container wrap="wrap" spacing={1} justifyContent="flex-end">
        <Grid item>
          <Button color="primary" onClick={onSubmit} variant="contained">
            <FormattedMessage id="global.continue" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CommunicationStep;
