import { createReducer } from '@reduxjs/toolkit';
import { saveEntity } from './entities';
import { getContactId } from '../selectors/saleContract';

const initialState = {
  activeStep: 0,
  acceptMandate: false,
  acceptTerms: false,
  steps: [
    'sale-contract.steps-validate-cgv',
    'sale-contract.steps-choose-communication',
    'sale-contract.steps-sign-form',
  ],
};

const SET_STEP = 'saleContract/SET_STEP';
const SET_ACCEPT_TERMS = 'saleContract/SET_ACCEPT_TERMS';
const SET_ACCEPT_MANDATE = 'saleContract/SET_ACCEPT_MANDATE';
const RESET = 'saleContract/RESET';

// ------------------------------------
// Actions
// ------------------------------------

export const setStep = (step) => ({
  type: SET_STEP,
  step,
});

export const setAcceptTerms = (accept) => ({
  type: SET_ACCEPT_TERMS,
  accept,
});

export const setAcceptMandate = (accept) => ({
  type: SET_ACCEPT_MANDATE,
  accept,
});

export const reset = () => ({
  type: RESET,
});

export const changePictureAllowed = (value) => async (dispatch, getState) => {
  dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED' });
  const contactId = getContactId(getState());
  try {
    await dispatch(
      saveEntity(contactId, {
        data: {
          pictureAllowed: value,
        },
      })
    );

    dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED_SUCCESS' });
  } catch ({ response }) {
    dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED_ERROR', response });
  }
};

export const saveFingerprint = (value) => async (dispatch, getState) => {
  dispatch({ type: 'saleContract/CHANGE_PICTURE_ALLOWED' });
  const contactId = getContactId(getState());
  try {
    await dispatch(
      saveEntity(contactId, {
        data: {
          fingerprintAllowed: value,
        },
      })
    );

    dispatch({ type: 'saleContract/CHANGE_FINGERPRINT_SUCCESS' });
  } catch ({ response }) {
    dispatch({ type: 'saleContract/CHANGE_FINGERPRINT_ERROR', response });
  }
};

// ------------------------------------
// Handlers
// ------------------------------------

const handleSetStep = (prevState, { step }) => ({
  ...prevState,
  activeStep: step,
});

const handleAcceptMandate = (prevState, { accept }) => ({
  ...prevState,
  acceptMandate: accept,
});

const handleAcceptTerms = (prevState, { accept }) => ({
  ...prevState,
  acceptTerms: accept,
});

const handleReset = () => initialState;

// ------------------------------------
// Reducer
// ------------------------------------

export default createReducer(initialState, {
  [SET_STEP]: handleSetStep,
  [SET_ACCEPT_MANDATE]: handleAcceptMandate,
  [SET_ACCEPT_TERMS]: handleAcceptTerms,
  [RESET]: handleReset,
});
